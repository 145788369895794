import { useContext, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { PaymentMethodTypes } from '../../PaymentCaptureMethod/constants';
import { splitsList } from '../../../constants';
import useExistingPaymentMethod from '../../PaymentCaptureMethod/hooks/useExistingPaymentMethod';
import useSplits from '../../../hooks/useSplits';
import { PaymentContext } from '../PaymentContextProvider';
import { trackApplePayFeatureAvailability, trackApplePayWebRollout } from '../../../helpers/switch/analytics';

const usePaymentMethods = () => {
  const { isWebview, isMethodChange } = useContext(PaymentContext);
  const { existingPaymentType } = useExistingPaymentMethod();
  const splitReady = useSelector(state => state.split?.fetched);

  const splits = useSplits([splitsList.applePayWebRollout, splitsList.disableApplePayRecurly]);
  const disableApplePay = splits[splitsList.disableApplePayRecurly];
  const applePayUpsellTest = splits[splitsList.applePayWebRollout];

  const alreadyUsingApplePay = existingPaymentType === PaymentMethodTypes.ApplePay;
  const splitTreatment = applePayUpsellTest ? 'on' : 'off';

  const applePaySupported = typeof window !== 'undefined' && !!window?.ApplePaySession?.canMakePayments?.();
  const isEligibleForApplePayWebTest = !isMethodChange && !isWebview;

  const availableMethods = useMemo(() => {
    const paymentMethods = [PaymentMethodTypes.Card];
    const changeMethods = [PaymentMethodTypes.Card];

    const methods = isMethodChange ? changeMethods : paymentMethods;

    if (!splitReady || !applePaySupported || disableApplePay) {
      return methods;
    }

    if (isMethodChange) {
      changeMethods.push(PaymentMethodTypes.ApplePay);
    }

    if (isWebview || alreadyUsingApplePay || applePayUpsellTest) {
      paymentMethods.push(PaymentMethodTypes.ApplePay);
    }

    return methods;
  }, [
    splitReady,
    applePaySupported,
    disableApplePay,
    isMethodChange,
    isWebview,
    alreadyUsingApplePay,
    applePayUpsellTest,
  ]);

  useEffect(() => {
    if (!splitReady || !applePaySupported) return;

    if (applePaySupported) {
      if (disableApplePay) {
        trackApplePayFeatureAvailability('off');
      } else {
        trackApplePayFeatureAvailability('on');
        if (isEligibleForApplePayWebTest) {
          trackApplePayWebRollout(alreadyUsingApplePay ? 'existing' : splitTreatment);
        }
      }
    }
  }, [
    splitReady,
    applePaySupported,
    disableApplePay,
    isEligibleForApplePayWebTest,
    alreadyUsingApplePay,
    splitTreatment,
  ]);

  return availableMethods;
};

export default usePaymentMethods;
