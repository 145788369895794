import Analytics, {
  InteractionInputEvent,
  MilestoneInputEvent,
  OptimisationInputEvent,
} from '@experian-uk/web-common-analytics';

// a user has attempted an invalid product change and has been redirected away from the switch page
export const trackSwitchRejected = () => {
  Analytics.publish(
    MilestoneInputEvent.fromObject({
      product_movement_switch_rejected: {
        interaction_status: false,
      },
    })
  );
};

export const trackSwitchPaymentMethodSelected = paymentMethodType => {
  Analytics.publish(
    InteractionInputEvent.fromObject({
      component: 'prodmove_switch_form_payment_method_select',
      action: 'input',
      status: true,
      data: {
        payment_method: paymentMethodType,
      },
    })
  );
};

export const trackSwitchPaymentCardNumValid = () => {
  Analytics.publish(
    InteractionInputEvent.fromObject({
      component: 'prodmove_switch_form_card_number',
      action: 'input',
      status: true,
    })
  );
};

export const trackSwitchPaymentFirstNameValid = () => {
  Analytics.publish(
    InteractionInputEvent.fromObject({
      component: 'prodmove_switch_form_first_name',
      action: 'input',
      status: true,
    })
  );
};

export const trackSwitchPaymentLastNameValid = () => {
  Analytics.publish(
    InteractionInputEvent.fromObject({
      component: 'prodmove_switch_form_last_name',
      action: 'input',
      status: true,
    })
  );
};

export const trackSwitchPaymentCardExpiryMonthValid = () => {
  Analytics.publish(
    InteractionInputEvent.fromObject({
      component: 'prodmove_switch_form_card_month_expiry',
      action: 'input',
      status: true,
    })
  );
};

export const trackSwitchPaymentCardExpiryYearValid = () => {
  Analytics.publish(
    InteractionInputEvent.fromObject({
      component: 'prodmove_switch_form_card_year_expiry',
      action: 'input',
      status: true,
    })
  );
};

export const trackSwitchPaymentCvvValid = () => {
  Analytics.publish(
    InteractionInputEvent.fromObject({
      component: 'prodmove_switch_form_cvv',
      action: 'input',
      status: true,
    })
  );
};

export const trackSwitchFormAttempt = (successful, paymentMethodType) => {
  Analytics.publish(
    InteractionInputEvent.fromObject({
      component: 'prodmove_confirm_purchase_button',
      action: 'click',
      status: successful,
      data: {
        payment_method: paymentMethodType,
      },
    })
  );
};

export const trackSwitchInitiatePurchase = successful => {
  Analytics.publish(
    MilestoneInputEvent.fromObject({
      product_movement_initiate_purchase: {
        interaction_status: successful,
      },
    })
  );
};

export const trackSwitchAuthReady = () => {
  Analytics.publish(
    MilestoneInputEvent.fromObject({
      product_movement_challenge_page_load: {
        interaction_status: true,
      },
    })
  );
};

export const trackSwitchAuthSuccess = () => {
  Analytics.publish(
    MilestoneInputEvent.fromObject({
      product_movement_auth_success: {
        interaction_status: true,
      },
    })
  );
};

export const trackSwitchAuthFailure = errorCode => {
  Analytics.publish(
    MilestoneInputEvent.fromObject({
      product_movement_auth_error: {
        error_type: errorCode ?? 'unknown',
      },
    })
  );
};

export const trackSwitchAuthorizePurchase = successful => {
  Analytics.publish(
    MilestoneInputEvent.fromObject({
      product_movement_authorize_purchase: {
        interaction_status: successful,
      },
    })
  );
};
export const trackSwitchFulfillmentStarted = () => {
  Analytics.publish(
    MilestoneInputEvent.fromObject({
      product_movement_fulfillment_started: {
        interaction_status: true,
      },
    })
  );
};

export const trackSwitchFulfillmentAttempt = successful => {
  Analytics.publish(
    MilestoneInputEvent.fromObject({
      product_movement_fulfillment: {
        interaction_status: successful,
      },
    })
  );
};

export const trackSwitchGoBackClick = () => {
  Analytics.publish(
    InteractionInputEvent.fromObject({
      component: 'prod_move_upsell_go_back_cta',
      action: 'click',
      status: 'success',
    })
  );
};

export const trackPaymentMethodChangeStarted = (paymentOptionsAvailable, currentPaymentMethod) => {
  Analytics.publishOnce(
    InteractionInputEvent.fromObject({
      component: 'change_payment_method',
      action: 'click',
      status: 'success',
      data: {
        payment_options_available: paymentOptionsAvailable,
        current_payment_method: currentPaymentMethod,
      },
    })
  );
};

export const trackPaymentMethodChangeCancel = () => {
  Analytics.publish(
    InteractionInputEvent.fromObject({
      component: 'cancel_payment_update_button',
      action: 'click',
      status: 'success',
    })
  );
};

export const trackPaymentMethodChangeFinished = ({ updateStatus, attemptedUpdateMethod }) => {
  Analytics.publish(
    MilestoneInputEvent.fromObject({
      update_subscription_payment_method: {
        status: updateStatus,
        data: {
          attempted_method: attemptedUpdateMethod,
        },
      },
    })
  );
};

export const trackPaymentMethodChangeError = error => {
  const onErrorECD = MilestoneInputEvent.fromObject({
    error: {
      component: 'payment_update',
      application: 'product_movement',
      error_state: error,
    },
  });
  Analytics.publish(onErrorECD);
};

export const trackApplePayFeatureAvailability = treatment => {
  Analytics.publishOnce(
    OptimisationInputEvent.fromObject({
      testName: 'applepay_blanket_feature_toggle',
      testArea: 'Product Movement',
      testVariant: treatment,
    })
  );
};

export const trackApplePayWebRollout = treatment => {
  Analytics.publishOnce(
    OptimisationInputEvent.fromObject({
      testName: 'applepay_web_payment_method',
      testArea: 'Product Movement',
      testVariant: treatment,
    })
  );
};

export const trackBillingProviderIdentifier = provider => {
  Analytics.publishOnce(
    OptimisationInputEvent.fromObject({
      testName: 'billing_provider_migration',
      testArea: 'Product Movement',
      testVariant: provider,
    })
  );
};
